const documentsSelectInit = () => {
	const select = document.querySelector('.documents__select-button select') || false;

	if (!select) {
		return;
	}

	const documentsGroups = document.querySelectorAll('.documents__documents-group');

	select.addEventListener('change', (event) => {
		if (event.target.value === 'all') {
			documentsGroups.forEach((el) => {
				el.classList.remove('hidden');
			});
		} else {
			documentsGroups.forEach((el) => {
				el.classList.add('hidden');
			});
			document.querySelector(`#${event.target.value}`).classList.remove('hidden');
		}
	});
};

export default documentsSelectInit;
