import vars from '../helpers';
import JustValidate from 'just-validate';
import IMask from 'imask';

const formInit = () => {
	const lang = document.querySelector('input[name="lang"]').value || false;

	document.querySelectorAll('.join-form__field').forEach((el) => {
		if (window.localStorage.getItem(el.id)) {
			el.value = window.localStorage.getItem(el.id);
		}
		el.addEventListener('blur', (e) => {
			window.localStorage.setItem(e.target.id, e.target.value);
		});
	});

	document.querySelectorAll('#inn').forEach((inn) => {
		const innMask = new IMask(
			inn,
			{
				mask: '000000000000',
			});
	});

	document.querySelectorAll('#phone').forEach((phone) => {
		const phoneMask = new IMask(
			phone,
			{
				mask: '+{7}(000)000-00-00',
			});
	});

	if (document.querySelector('#snilsp')) {
		const phoneSnilsp = new IMask(
			document.querySelector('#snilsp'),
			{
				mask: '000-000-000-00',
			});
	}

	const applicantForm1 = document.querySelector('[data-form="1"]') ?? false;
	const applicantForm2 = document.querySelector('[data-form="2"]') ?? false;

	async function sendData(data) {
		return fetch('/gd_en', {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: data,
		});
	}

	async function sendDataAi(data) {
		const api = vars.html.classList.contains('page-export-declaration') ? '/export-generate-doc' : '/generate-doc';

		return fetch(api, {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: data,
		});
	}

	function serializeForm(formNode) {
		let data = new URLSearchParams(Object.fromEntries(new FormData(formNode)));

		return data.toString();
	}

	async function handleFormSubmit(event) {
		if (lang === 'en' || lang === 'ru' && applicantForm1 && applicantForm1.classList.contains('is-hidden') || vars.html.classList.contains('page-genai-declaration') || vars.html.classList.contains('page-export-declaration')) {
			event.preventDefault();
			const data = serializeForm(applicantForm2);
			const response = await sendDataAi(data);
			if (response.ok) {
				let blob = await response.blob();
				let docName = response.headers.get('content-disposition').split('filename=')[1].replaceAll('"', '');
				const href = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = href;
				a.download = docName;
				document.body.appendChild(a);
				a.click();
				a.remove();
				event.target.reset();
			} else {
				alert(`Ошибка HTTP: ${response.status}`);
			}
		} else {
			const widget = new CloudsignsWidget('joinForm');

			widget.startSign({
				clientKey: 'ck_123',
				templateKey: 'apply_ru',
				snils: document.getElementById('snilsp').value,
				documentParams: {
					timestamp: Date.now(),
				},
				// запустилось окно с подписанием документов
				onStart: () => {
					console.info('Request success!');
				},

				// окно виджета закрыто до завершения процесса подписания (крестик или "закрыть" до получения подтверждения подписания или ошибки от ГК)
				onCancel: () => {
					console.error('Sign cancelled');
				},

				// документ успешно подписан, в data хранятся ссылки на подписанный файл и откреплённую подпись
				onSignSuccess: (data) => {
					applicantForm1.querySelector('#sign-result').innerHTML = `
						<div>
							Вы можете скачать <a style="text-decoration: underline;" href="${data?.document}" target="_blank">файл документа</a>  и <a style="text-decoration: underline;" href="${data?.signature}" target="_blank">файл подписи</a> документа. Документ будет доступен в системе вместе с другими вашими документами в разделе «Мои документы».
						</div>`;
					console.info('Sign success!', data);
				},
				// ошибка при подписании документа (ошибка передачи в ГК, отказ в подписании в приложении ГК, или что-то иное)
				onSignError: (error) => {
					console.error('Sign error', error);
				},
			});
		}
	}

	let validation;
	let validation1;

	if (lang === 'ru' && vars.html.classList.contains('page-home')) {
		validation1 = new JustValidate(applicantForm1, {
			errorFieldCssClass: 'is-invalid',
		});

		validation = new JustValidate(applicantForm2, {
			errorFieldCssClass: 'is-invalid',
		});

		validation1
			.addField('#fio', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#fiop', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#spos', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#phone', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
				{
					rule: 'minLength',
					value: 16,
					errorMessage: 'Некорректный номер телефона',
				},
			])
			.addField('#dolg', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#inn', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#dolgp', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#policy1', [
				{
					rule: 'required',
					errorMessage: '',
				},
			])
			.addField('#dataProcessing1', [
				{
					rule: 'required',
					errorMessage: '',
				},
			])
			.addField('#org', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
			])
			.addField('#email', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
				{
					rule: 'email',
					errorMessage: 'Некорректный email',
				},
			])
			.addField('#snilsp', [
				{
					rule: 'required',
					errorMessage: 'Обязательное поле',
				},
				{
					rule: 'minLength',
					value: 14,
					errorMessage: 'Некорректный СНИЛС',
				},
				{
					rule: 'maxLength',
					value: 14,
					errorMessage: 'Некорректный СНИЛС',
				},
			]);
	} else {
		validation = new JustValidate(applicantForm2, {
			errorFieldCssClass: 'is-invalid',
		});
	}

	validation
		.addField('#fio', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#fiop', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#spos', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#phone', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
			{
				rule: 'minLength',
				value: 16,
				errorMessage: 'Некорректный номер телефона',
			},
		])
		.addField('#dolg', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#inn', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#dolgp', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#policy', [
			{
				rule: 'required',
				errorMessage: '',
			},
		])
		.addField('#dataProcessing', [
			{
				rule: 'required',
				errorMessage: '',
			},
		])
		.addField('#org', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
		])
		.addField('#email', [
			{
				rule: 'required',
				errorMessage: 'Обязательное поле',
			},
			{
				rule: 'email',
				errorMessage: 'Некорректный email',
			},
		]);

	if (vars.html.classList.contains('page-home')) {
		validation.onSuccess(handleFormSubmit);
		validation1.onSuccess(handleFormSubmit);
	} else {
		validation.onSuccess(handleFormSubmit);
	}
};

export default formInit;
