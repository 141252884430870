import vars from '../helpers';

const introHead = document.querySelector('.intro__head') ?? false;
const introHeadLine = document.querySelector('.intro__head-line') ?? false;

scrollLock.disablePageScroll();

document.addEventListener('DOMContentLoaded', () => {
	gsap.timeline().to(vars.preloader, {
		onStart() {
			vars.main.classList.remove('is-hidden');
			vars.header.classList.remove('is-hidden');
			vars.footer.classList.remove('is-hidden');
		},
		onComplete() {
			vars.preloader.classList.add('is-hidden');
			scrollLock.enablePageScroll();

			if (introHead) {
				introHead.classList.add('is-animate');
			}

			if (introHeadLine) {
				gsap.delayedCall(1, () => {
					introHeadLine.classList.add('is-loading');
				});

				gsap.delayedCall(5, () => {
					introHeadLine.classList.remove('is-loading');
					introHeadLine.classList.add('is-animate');
				});
			}
		},
		delay: 1,
		duration: 0.5,
		opacity: 0,
		ease: 'power2.out',
		clearProps: 'all',
	});
});
