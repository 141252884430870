const popup = document.querySelector('.popup') ?? false;
const popupButton = document.querySelectorAll('.popup__close, .popup__overlay') ?? false;
const popupContainerRegion = document.querySelector('.popup__container--region') ?? false;
const popupContainerPeople = document.querySelector('.popup__container--people') ?? false;

const popupRegionContent = (value, id) => {
	const img = popupContainerRegion.querySelector('.popup__img img');
	const title = popupContainerRegion.querySelector('.popup__title');
	const more = popupContainerRegion.querySelector('.popup__more');

	if (value) {
		img.setAttribute('src', `/assets/${__regions[id].photo}`);
		title.innerHTML = __regions[id].title;
		more.innerHTML = __regions[id].fullDesc;
	} else {
		img.setAttribute('src', '');
		title.innerHTML = '';
		more.innerHTML = '';
	}
};

const popupPeopleContent = (value, id) => {
	const photo = popupContainerPeople.querySelector('.popup__photo img');
	const name = popupContainerPeople.querySelector('.popup__name');
	const pos = popupContainerPeople.querySelector('.popup__pos');
	const desc = popupContainerPeople.querySelector('.popup__desc');
	const more = popupContainerPeople.querySelector('.popup__more');

	if (value === 'individual') {
		photo.setAttribute('src', `/assets/${__individuals[id].photo}`);
		name.innerHTML = __individuals[id].name;
		pos.innerHTML = __individuals[id].pos;
		desc.innerHTML = __individuals[id].reason;
		more.innerHTML = __individuals[id].bio;
	} else if (value === 'special') {
		photo.setAttribute('src', `/assets/${__specials[id].photo}`);
		name.innerHTML = __specials[id].name;
		pos.innerHTML = __specials[id].pos;
		desc.innerHTML = __specials[id].desc;
		more.innerHTML = __specials[id].bio;
	} else {
		photo.setAttribute('src', '');
		name.innerHTML = '';
		pos.innerHTML = '';
		desc.innerHTML = '';
		more.innerHTML = '';
	}
};

const popupOpen = (container) => {
	gsap.timeline()
		.from(popup, {
			onStart() {
				scrollLock.disablePageScroll(container);
				popup.classList.remove('is-hidden');
			},
			duration: 0.3,
			opacity: 0,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		})
		.from(container, {
			onStart() {
				container.classList.remove('is-hidden');
				container.classList.add('is-open');
			},
			duration: 0.3,
			opacity: 0,
			y: 50,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		}, '-50%');
};

const popupClose = (container) => {
	gsap.timeline()
		.to(popup, {
			onComplete() {
				scrollLock.enablePageScroll(container);

				if (container.classList.contains('popup__container--region')) {
					popupRegionContent(false, 0);
				}

				if (container.classList.contains('popup__container--people')) {
					popupPeopleContent(false, 0);
				}

				container.classList.remove('is-open');
				container.classList.add('is-hidden');
				popup.classList.add('is-hidden');
			},
			duration: 0.3,
			opacity: 0,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		});
};

const popupInit = () => {
	if (!popup) {
		return;
	}

	const regions = document.querySelectorAll('[data-region]');
	const individuals = document.querySelectorAll('[data-individual]');
	const specials = document.querySelectorAll('[data-special]');

	regions.forEach((el) => {
		el.addEventListener('click', () => {
			const id = Number(el.dataset.region) - 1;

			popupRegionContent(true, id);
			popupOpen(popupContainerRegion);
		});
	});

	individuals.forEach((el) => {
		el.addEventListener('click', () => {
			const id = Number(el.dataset.individual) - 1;

			popupPeopleContent('individual', id);
			popupOpen(popupContainerPeople);
		});
	});

	specials.forEach((el) => {
		el.addEventListener('click', () => {
			const id = Number(el.dataset.special) - 1;

			popupPeopleContent('special', id);
			popupOpen(popupContainerPeople);
		});
	});

	popupButton.forEach((button) => {
		button.addEventListener('click', () => {
			const container = popup.querySelector('.popup__container.is-open');

			popupClose(container);
		});
	});
};

export default popupInit;
