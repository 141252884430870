import vars from '../helpers';

const header = document.querySelector('.header');
const headerBurger = header.querySelector('.header__burger');
const headerLink = header.querySelectorAll('.header__link');
const headerNav = header.querySelector('.header__nav');
const headerOverlay = header.querySelector('.header__overlay');

const navActive = () => {
	scrollLock.disablePageScroll(headerNav);
	headerNav.classList.add('is-flex');
	headerOverlay.classList.add('is-block');

	gsap.delayedCall(0.01, () => {
		header.classList.add('is-active');
	});
};

const navDeActive = () => {
	scrollLock.enablePageScroll(headerNav);
	header.classList.remove('is-active');

	gsap.delayedCall(0.2, () => {
		headerNav.classList.remove('is-flex');
		headerOverlay.classList.remove('is-block');
	});
};

const navFilter = () => {
	if (vars.isDesktop()) {
		return;
	}

	if (header.classList.contains('is-active')) {
		navDeActive();
	} else {
		navActive();
	}
};

const navInit = () => {
	headerBurger.addEventListener('click', () => {
		navFilter();
	});

	headerOverlay.addEventListener('click', () => {
		navFilter();
	});

	headerLink.forEach((link) => {
		link.addEventListener('click', () => {
			navFilter();
		});
	});

	window.addEventListener('resize', () => {
		if (vars.isDesktop() && header.classList.contains('is-active')) {
			navDeActive();
		}
	});

	window.addEventListener('scroll', () => {
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		if ((vars.isDesktop() || vars.isTablet()) && scrollTop > 120) {
			header.classList.add('is-scroll');
		} else if (vars.isMobile() && scrollTop > 25) {
			header.classList.add('is-scroll');
		} else {
			header.classList.remove('is-scroll');
		}
	}, false);
};

export default navInit;
