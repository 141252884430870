const aboutCode = document.querySelector('.about-code') ?? false;
const formOverlay = document.querySelector('.home__about-overlay') ?? false;
const aboutCodeBtn = document.querySelector('.about__about-btn') ?? false;

const formActive = () => {
	scrollLock.disablePageScroll(aboutCode);
	aboutCode.classList.add('is-flex');
	formOverlay.classList.add('is-block');

	gsap.delayedCall(0.01, () => {
		aboutCode.classList.add('is-active');
		formOverlay.classList.add('is-active');
	});
};

const formDeActive = () => {
	scrollLock.enablePageScroll(aboutCode);
	aboutCode.classList.remove('is-active');
	formOverlay.classList.remove('is-active');
	formOverlay.classList.remove('is-block');

	gsap.delayedCall(0.2, () => {
		aboutCode.classList.remove('is-flex');
	});
};

const formFilter = () => {
	if (aboutCode.classList.contains('is-active')) {
		formDeActive();
	} else {
		formActive();
	}
};

const aboutCodeInit = () => {
	if (!aboutCode) {
		return;
	}

	const closeBtn = document.querySelector('.about-code__close');

	aboutCodeBtn.addEventListener('click', () => {
		formFilter();
	});

	closeBtn.addEventListener('click', () => {
		formFilter();
	});

	formOverlay.addEventListener('click', () => {
		formFilter();
	});
};

export default aboutCodeInit;
