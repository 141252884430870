import vars from '../helpers';

const introViewsImg = document.querySelector('.intro__views-img') ?? false;
const introViewsVideo = document.querySelector('.intro__views-video') ?? false;

const introInit = () => {
	if (!vars.html.classList.contains('page-export-declaration')) {
		return;
	}

	introViewsVideo
		.play()
		.then(() => {
			introViewsImg.classList.add('is-hidden');
			introViewsVideo.classList.remove('is-hidden');
		});
};

export default introInit;
