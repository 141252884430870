import Swiper, {Pagination, Navigation} from 'swiper';

const sliderInit = () => {
	const eventsSlider = document.querySelector('.events__items') ?? false;
	const commissionSlider = document.querySelector('.commission__items') ?? false;
	const actorsSlider = document.querySelector('.actors__list') ?? false;
	const practicesSlider = document.querySelector('.practices__list') ?? false;

	const perGroup = (selector) => {
		const items = document.querySelectorAll(selector);

		if (items.length >= 10) {
			return 2;
		}

		return 1;
	};

	if (commissionSlider) {
		const commissionSwiper = new Swiper(commissionSlider, {
			speed: 500,
			spaceBetween: 0,
			slidesPerView: 'auto',
			modules: [Pagination, Navigation],
			slidesPerGroup: perGroup('.commission__item'),
			navigation: {
				nextEl: '.commission__button-next',
				prevEl: '.commission__button-prev',
			},
			pagination: {
				el: '.commission__pagination',
				clickable: true,
			},
		});
	}

	if (eventsSlider) {
		const eventsSwiper = new Swiper(eventsSlider, {
			speed: 500,
			spaceBetween: 0,
			slidesPerView: 'auto',
			modules: [Pagination, Navigation],
			slidesPerGroup: perGroup('.events__item'),
			navigation: {
				nextEl: '.events__button-next',
				prevEl: '.events__button-prev',
			},
			pagination: {
				el: '.events__pagination',
				clickable: true,
			},
		});
	}

	if (actorsSlider) {
		const actorsSwiper = new Swiper(actorsSlider, {
			speed: 500,
			spaceBetween: 12,
			slidesPerView: 'auto',
			modules: [Pagination],
		});
	}

	if (practicesSlider) {
		const practicesSwiper = new Swiper(practicesSlider, {
			speed: 500,
			spaceBetween: 0,
			slidesPerView: 'auto',
			modules: [Pagination],
			pagination: {
				el: '.practices__pagination',
				clickable: true,
			},
		});
	}
};

export default sliderInit;
