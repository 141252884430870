const survey = document.querySelector('.survey') ?? false;
const surveyModal = document.querySelector('.survey__modal') ?? false;
const surveyButton = document.querySelectorAll('.button--survey, .survey__close, .survey__overlay') ?? false;

const surveyOpen = (container) => {
	gsap.timeline()
		.from(survey, {
			onStart() {
				scrollLock.disablePageScroll(container);
				survey.classList.remove('is-hidden');
			},
			delay: 3,
			duration: 0.3,
			opacity: 0,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		})
		.from(container, {
			duration: 0.3,
			opacity: 0,
			y: 50,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		}, '-50%');
};

const surveyClose = (button, container) => {
	gsap.timeline()
		.to(survey, {
			onStart() {
				document.cookie = 'surveryModalClose';
			},
			onComplete() {
				scrollLock.enablePageScroll(container);
				survey.classList.add('is-hidden');

				if (button.dataset.href) {
					window.open(button.dataset.href, '_self');
				}
			},
			duration: 0.3,
			opacity: 0,
			ease: 'Power1.easeOut',
			clearProps: 'all',
		});
};

const surveyInit = () => {
	if (!survey) {
		return;
	}

	if (document.cookie.indexOf('surveryModalClose') === -1) {
		surveyOpen(surveyModal);
	} else {
		return;
	}

	surveyButton.forEach((button) => {
		button.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			surveyClose(button, surveyModal);
		});
	});
};

export default surveyInit;
