import vars from '../helpers';

const joinFormInit = () => {
	const joinForm = document.querySelector('.join-form');
	const formOverlay = document.querySelector('.home__overlay');
	const actorsJoinBtn = document.querySelector('.actors__joinButton');
	const formOpenBtn = document.querySelectorAll('.contact__button, .contact__image, .join-form__close, .join-form__close-second, .home__overlay, .actors__joinButton, .header__link-joinform');
	const formSelect = joinForm.querySelectorAll('.join-form__select') ?? false;

	const formActive = () => {
		scrollLock.disablePageScroll(joinForm);
		joinForm.classList.add('is-flex');
		formOverlay.classList.add('is-block');

		gsap.delayedCall(0.01, () => {
			joinForm.classList.add('is-active');
			formOverlay.classList.add('is-active');
		});
	};

	const formDeActive = () => {
		scrollLock.enablePageScroll(joinForm);
		joinForm.classList.remove('is-active');
		formOverlay.classList.remove('is-active');
		formOverlay.classList.remove('is-block');

		setTimeout(() => {
			document.querySelector('.join-form__content').classList.remove('hidden');
			document.querySelector('.join-form__thanks').classList.add('hidden');
		}, 300);

		gsap.delayedCall(0.2, () => {
			joinForm.classList.remove('is-flex');
		});
	};

	const formFilter = () => {
		if (joinForm.classList.contains('is-active')) {
			formDeActive();
		} else {
			formActive();
		}
	};

	const formSelection = (select) => {
		let current = select.dataset.select;
		let prev = current === '1' ? '2' : '1';
		let selectCurrent = joinForm.querySelector(`[data-select="${current}"]`);
		let formCurrent = joinForm.querySelector(`[data-form="${current}"]`);
		let selectPrev = joinForm.querySelector(`[data-select="${prev}"]`);
		let formPrev = joinForm.querySelector(`[data-form="${prev}"]`);

		selectPrev.classList.remove('is-active');
		formPrev.classList.add('is-hidden');
		selectCurrent.classList.add('is-active');
		formCurrent.classList.remove('is-hidden');
	};

	if (vars.html.classList.contains('page-winners')) {
		return;
	}

	if (vars.html.classList.contains('page-home')) {
		actorsJoinBtn.addEventListener('click', () => {
			formFilter();
		});
	}

	formOpenBtn.forEach((btn) => {
		btn.addEventListener('click', () => {
			formFilter();
		});
	});

	if (formSelect) {
		formSelect.forEach((select) => {
			select.addEventListener('click', () => {
				formSelection(select);
			});
		});
	}
};

export default joinFormInit;
